var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lp-index",class:{ dark: _vm.blockController.dark }},[_c('edit-toolbar',{on:{"zoomFit":_vm.setZoomFit}}),_c('div',{staticClass:"lp-index-panel",style:({
      left: 0,
      width: (_vm.leftPanelSelected > -1 ? 480 : 80) + 'px'
    })},[_c('left-panel',{attrs:{"leftPanelsTop":_vm.leftPanelsTotal,"leftPanelsBottom":_vm.leftPanelsBottom,"leftPanelSelected":_vm.leftPanelSelected,"dark":_vm.blockController.dark},on:{"update:leftPanelSelected":function($event){_vm.leftPanelSelected=$event},"update:left-panel-selected":function($event){_vm.leftPanelSelected=$event},"slideChanged":function($event){return _vm.$emit('slideChanged', _vm.blockController)}}})],1),_c('main',{staticClass:"lp-index-main",style:({
      marginLeft: (_vm.leftPanelSelected > -1 ? 400 : 0) + 'px',
      marginRight: (_vm.rightPanelSelected > -1 ? 400 : 0) + 'px'
    })},[(_vm.imported)?_c('block-canvas-readonly',{key:_vm.slideId + '_imported',ref:"canvas",attrs:{"slide-id":_vm.slideId,"blocks":_vm.blocks,"defaultBlockStyles":_vm.defaultBlockStyles,"settingStyles":_vm.settingStyles}}):_c('block-canvas',{key:_vm.slideId,ref:"canvas",attrs:{"slide-id":_vm.slideId,"blocks":_vm.blocks,"defaultBlockStyles":_vm.defaultBlockStyles,"settingStyles":_vm.settingStyles},on:{"create:draft":function($event){return _vm.$emit('create:draft', $event)}}})],1),_c('div',{staticClass:"lp-index-panel",style:({
      right: 0,
      width: (_vm.rightPanelSelected > -1 ? 480 : 80) + 'px'
    })},[_c('right-panel',{key:_vm.slideId,attrs:{"rightPanelSelected":_vm.rightPanelSelected,"dark":_vm.blockController.dark,"readonly":_vm.imported},on:{"update:rightPanelSelected":function($event){_vm.rightPanelSelected=$event},"update:right-panel-selected":function($event){_vm.rightPanelSelected=$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }