<template>
  <fragment>
    <div v-for="x in gridsX" :key="`${x}x`" :style="styleGridX(x)" />
    <div v-for="y in gridsY" :key="`${y}y`" :style="styleGridY(y)" />
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
  props: {
    scale: Number,
    gridX: Number,
    gridY: Number,
    originX: Number,
    originY: Number,
    canvasWidth: Number,
    canvasHeight: Number
  },
  components: { Fragment },
  computed: {
    gridsX() {
			return this.getGrids(this.canvasWidth, this.gridX)
    },
    gridsY() {
			return this.getGrids(this.canvasHeight, this.gridY)
    },
    viewerSize() {
      return this.canvasWidth + this.canvasHeight + this.originX + this.originY
    }
  },
  watch: {
    viewerSize() {
      this.$emit("zoomfit")
    }
  },
  methods: {
		getGrids(targetDimensionToSplit, splitSize) {
			const grids = []
			const totalGrids = (targetDimensionToSplit / this.scale) / splitSize
			for (let i = 0; i < totalGrids; i++) {
				grids[i] = splitSize * i
			}
			return grids
		},
    styleGridX(x) {
      return {
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: `${x}px`,
        width: "1px",
        background: "rgba(0, 0, 0, 0.1)"
      }
    },
    styleGridY(y) {
      return {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: `${y}px`,
        height: "1px",
        background: "rgba(0, 0, 0, 0.1)"
      }
    }
  }
}
</script>
