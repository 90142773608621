<template>
  <div id="lp-index" class="lp-index">
    <edit-toolbar @zoomFit="setZoomFit" />
    <div
      class="lp-index-panel"
      :style="{
        left: 0,
        width: (leftPanelSelected > -1 ? 480 : 80) + 'px'
      }">
      <left-panel
        :leftPanelsTop="leftPanelsTop"
        :leftPanelsBottom="leftPanelsBottom"
        :leftPanelSelected.sync="leftPanelSelected"
        @slideChanged="$emit('slideChanged', blockController)" />
    </div>
    <main
      class="lp-index-main"
      :style="{
        marginLeft: (leftPanelSelected > -1 ? 400 : 0) + 'px',
        marginRight: (rightPanelSelected > -1 ? 400 : 0) + 'px'
      }">
      <footer-arrows
        @slideChanged="$emit('slideChanged', blockController)"
        @prev="goToPrevSlide"
        @next="goToNextSlide"
        @update="goToSlideIndex"
        @toggleFullscreen="toggleFullscreen"
        @toggleSubtitles="toggleSubtitles"
        @selectDefaultLang="selectDefaultLang"
        :slide="lessonPlanController.selectedSlideIndex + 1"
        :total="lessonPlanController.slidesVisible.length"
        :can-go-next="true"
        :can-go-prev="true"
        :isFullscreen="lessonPlanController.isFullscreen"
        :subtitlesActive="lessonPlanController.subtitlesActive"
        :defaultSubtitles="lessonPlanController.defaultSubtitles" />
      <block-canvas
        ref="canvas"
        :blocks="blocks"
        :key="slideId"
        :slide-id="slideId"
        :defaultBlockStyles="defaultBlockStyles"
        :settingStyles="settingStyles"
        @create:draft="$emit('create:draft', $event)"
        :globalVariables="globalVariables"
        class="p-block-designer-canvas"
        mode="edit"></block-canvas>
    </main>
    <div
      class="lp-index-panel"
      :style="{
        right: 0,
        width: (rightPanelSelected > -1 ? 480 : 80) + 'px'
      }">
      <right-panel :rightPanels="rightPanels" :rightPanelSelected.sync="rightPanelSelected" :key="slideId" />
    </div>

    <modal-progress
      :show="lessonPlanController.progressModal.show"
      :title="lessonPlanController.progressModal.title"></modal-progress>
  </div>
</template>

<script>
import LeftPanel from "../../../../components/structure/LeftPanel.vue"
import EditToolbar from "./EditToolbar.vue"
import BlockCanvas from "../../containers/BlockCanvas.vue"
import RightPanel from "../../../../components/structure/RightPanel.vue"
import blockController from "../../../_controllers/BlockController/index.js"
import Vue from "vue"
import BlockCanvasGrid from "../../containers/BlockCanvasGrid.vue"
import FooterArrows from "../../../../../commonInteractive/navigation/FooterArrows.vue"
import { ModalProgress } from "plant-common"

export default {
  name: "BlockDesigner",
  inject: ["lessonPlanController"],
  components: {
    LeftPanel,
    EditToolbar,
    RightPanel,
    BlockCanvas,
    BlockCanvasGrid,
    FooterArrows,
    ModalProgress
  },
  provide() {
    return {
      blockDefinitions: this.blockDefinitions,
      blockController: this.blockController,
      // blockEventBus in edit mode to avoid error in console when using inject
      blockEventBus: new Vue()
    }
  },
  props: {
    blocks: Array,
    slideId: String,
    blockDefinitions: Object,
    defaultBlockStyles: Object,
    settingStyles: Object,
    leftPanelsTop: Array,
    leftPanelsBottom: Array,
    rightPanels: Array,
    isBlockSelected: Boolean,
    gridSize: Array,
    availableStencils: Array,
    globalVariables: Array
  },
  data() {
    return {
      leftPanelSelected: -1,
      rightPanelSelected: -1,
      blockController: blockController(this)
    }
  },
  watch: {
    blocks: {
      deep: true,
      handler() {
        this.blockController.blocks = this.blocks
      }
    },
    gridSize: {
      deep: true,
      handler() {
        this.blockController.gridSize = this.gridSize
      }
    },
    availableStencils: {
      deep: true,
      handler() {
        this.blockController.availableStencils = this.availableStencils
      }
    },
    slideId() {
      if (this.rightPanelSelected > 4) this.rightPanelSelected = 0
    }
  },
  methods: {
    setZoomFit() {
      this.$nextTick(() => {
        this.$refs["canvas"].zoomFit()
      })
    },
    goToPrevSlide() {
      this.lessonPlanController.prevSlide()
    },
    goToNextSlide() {
      this.lessonPlanController.nextSlide()
    },
    goToSlideIndex(number) {
      this.lessonPlanController.selectSlideByIndex(number - 1)
    },
    toggleFullscreen() {
      this.lessonPlanController.toggleFullscreen()
    },
    toggleSubtitles() {
      this.lessonPlanController.toggleSubtitles()
    },
    selectDefaultLang(lang) {
      this.lessonPlanController.selectDefaultLang(lang)
    }
  }
}
</script>

<style>
/* Display complete canvas with footer */
.p-block-designer-canvas.lp-slide-wrapper,
.p-block-designer-canvas .lp-slide {
  bottom: 35px;
}
</style>
