<template>
  <div v-if="slideId == '0' || !slideId" class="text-center">
    <v-container grid-list-md text-xs-center>
      <v-layout row wrap>
        <v-flex xs12>
          <div class="lp-tree-edit-no-slide-img-container">
            <img
              class="lp-tree-edit-no-slide-img"
              :src="require('../../../components/slides/placeholder-no-slide.svg')" />
          </div>
        </v-flex>
        <v-flex xs12>
          <v-btn class="mt-4" outline color="default" @click="addCard">
            <v-icon left>mdi-shape-rectangle-plus</v-icon>
            Add
            <div v-if="isLayoutParent">&nbsp;Layout</div>
            <div v-else>&nbsp;Slide</div>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  <div
    v-else
    class="lp-slide-wrapper"
    :class="{ dark: blockController.dark }"
    ref="viewer"
    tabindex="0"
    :style="settingStyles.wrapper"
    @click.self="cleanSelection"
    @drop="onBlockDrop"
    @dragover.prevent
    @dragenter.prevent
		@keyup.alt="handleShortcuts">
    <div class="lp-slide" ref="canvas" :style="slideStyles">
			<block-canvas-grid
				@zoomfit="setZoomFit"
				v-if="blockController.gridSize[0] > 1"
				:scale="blockController.scale"
				:origin-x="blockController.origin.x"
				:origin-y="blockController.origin.y"
				:grid-x="blockController.gridSize[0]"
				:grid-y="blockController.gridSize[1]"
				:canvasWidth="blockController.viewerWidth"
				:canvasHeight="blockController.viewerHeight" />

      <div :style="styleCanvas" @click.self="cleanSelection">
        <component
          v-for="block in blocks"
          :key="block.id"
          :is="getBlockComponent(block.type)"
          :value="block"
          :mode="mode ? mode : 'edit'"
          @blockClick="handleClick(block)"
          @blockDblClick="handleDoubleClick(block)"
          @create:draft="$emit('create:draft', $event)"
          :blockVariables="globalVariables" />
      </div>
      <block-overlay
        v-if="blockController.overlay"
        :defaultBlockStyles="defaultBlockStyles"
        @create:draft="$emit('create:draft', $event)" />
      <subtitles-overlay>
      </subtitles-overlay>
    </div>
  </div>
</template>

<script>
import onBlockDrop from "../../_mixins/onBlockDrop.vue"
import blockCanvasCommon from "../../_mixins/blockCanvasCommon.vue"
import BlockOverlay from "./BlockOverlay.vue"
import { Layout, Slide } from "../../../models/models"
import { PtModal } from "plant-common"
import _ from "lodash"
import SubtitlesOverlay from "./SubtitlesOverlay.vue"
import BlockCanvasGrid from "./BlockCanvasGrid.vue"

export default {
  name: "BlockCanvas",
  inject: ["blockController", "blockDefinitions", "lessonPlanController"],
  mixins: [onBlockDrop, blockCanvasCommon, PtModal],
  components: { BlockCanvasGrid, BlockOverlay, SubtitlesOverlay },
  props: {
    blocks: Array,
    defaultBlockStyles: Object,
    settingStyles: Object,
    globalVariables: Array,
    slideId: String,
    mode: String
  },
  data() {
    return {
      selectedBlock: null,
      selectedBlockState: null
    }
  },
  mounted() {
    if (this.$refs.viewer) {
      this.$refs.viewer.addEventListener("keydown", this.keyHandler)
    }
  },
  created() {
    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`selection-changed`, ({ block, state }) => {
        this.selectedBlock = block
        this.selectedBlockState = state
      })
    }
  },
  unmounted() {
    // remove global events
    if (this.$refs.viewer) {
      this.$refs.viewer.removeEventListener("keydown", this.keyHandler)
    }
  },
  computed: {
    isLayoutParent() {
      if (this.$parent && this.$parent.$options) {
        return this.$parent.$options._componentTag == "layout-designer"
      } else {
        return false
      }
    }
  },
  methods: {
		setZoomFit() {
			this.$nextTick(() => {
				this.zoomFit()
			})
		},
		toggleGrid() {
			if (this.lessonPlanController.gridSize[0] !== 1) {
				this.lessonPlanController.prevGridSize = [...this.lessonPlanController.gridSize]
				this.lessonPlanController.gridSize = [1, 1]
			} else {
				this.lessonPlanController.gridSize = this.lessonPlanController.prevGridSize || [80, 45]
			}
		},
		setNextGrid(direction) {
			if (this.lessonPlanController.gridIndex === 0) {
				return;
			}

			const directionMap = {
				up: 1,
				down: -1
			}

			const nextGridIndex = this.lessonPlanController.gridIndex + directionMap[direction];

			if (nextGridIndex === this.lessonPlanController.gridSizes.length) {
				return;
			}

			this.lessonPlanController.gridSize = this.lessonPlanController.gridSizes[nextGridIndex];
		},
		handleShortcuts(event) {
			if (event.altKey && event.code === "KeyG") {
				this.toggleGrid()
			}

			if (event.altKey && event.code === "ArrowUp") {
				this.setNextGrid('up')
			}

			if (event.altKey && event.code === "ArrowDown") {
				this.setNextGrid('down')
			}
		},
    keyHandler(evt) {
      var { code } = evt
      switch (code) {
        case "Delete":
          if (this.selectedBlock && this.selectedBlockState != "edit-content") {
            if (this.selectedBlock.protection && this.selectedBlock.protection.delete) {
              this.$modal("info", {
                title: "Can not delete current block",
                message: `Selected block "${
                  this.selectedBlock && this.selectedBlock.name ? this.selectedBlock.name : "Block"
                }" [${
                  this.selectedBlock && this.selectedBlock.type ? this.selectedBlock.type : "block"
                }] is protected against deletion`,
                ok: {
                  text: "Accept",
                  icon: "mdi-delete-off-outline"
                }
              })
            } else {
              this.$modal("confirm", {
                title: `Are you sure you want to delete selected block "${
                  this.selectedBlock && this.selectedBlock.name ? this.selectedBlock.name : "Block"
                }" [${this.selectedBlock && this.selectedBlock.type ? this.selectedBlock.type : "block"}]?`,
                accept: {
                  color: "error",
                  text: "Delete",
                  icon: "mdi-delete"
                },
                cancel: { text: "cancel", color: "shades" }
              }).then(r => {
                if (!r) {
                  this.$refs.viewer.focus()
                  return
                }
                this.blockController.deleteBlock(this.selectedBlock.id)
              })
            }
          }
          break
      }
    },
    cleanSelection() {
      this.blockController.overlay = null
      this.blockController.selectionClear()
    },
    handleClick(block) {
      this.$refs.viewer.focus()
      this.blockController.selectionClear()
      this.$nextTick(() => {
        this.blockController.selectionAdd(block.id)
      })

      if (this.$el.querySelector(".movable:not(.disabled)")) {
        this.$el.querySelector(".movable").classList.remove("edit-disabled")
      }
    },
    handleDoubleClick(block) {
      const allowDClickBlocks = ["audio", "video", "libraryContent"] //allow double click to play/pause content in edit mode
      if (block.protection.data && !allowDClickBlocks.includes(block.type)) {
        this.$el.querySelector(".movable").classList.add("edit-disabled")
      } else {
        this.blockController.selectionEdit(block.id)
      }
    },
    addCard() {
      if (this.isLayoutParent) {
        const layout = new Layout()
        layout.slide = new Slide()
        this.lessonPlanController.lessonPlanContents.layouts.push(layout)
        this.lessonPlanController.selectLayoutById(layout.id)
      } else {
        this.lessonPlanController.addSlide()
      }
    }
  }
}
</script>

<style scoped>
.lp-tree-edit-no-slide-img-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 800px;
  padding-top: 80px;
}
</style>
